@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: 0;
}

body {
    font-family: 'Montserrat';
}

.sticks {
    font-family: 'Stick';
    font-weight: 700;
}

.prose img {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}

.home-name {
    font-size: 150px;
}

.bullet-point-list {
    list-style-type: "▶  ";
}

.bullet-point-list > li::marker {
    color: red;
}
